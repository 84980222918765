import { library } from '@fortawesome/fontawesome-svg-core'
import {
	faArrowDown,
	faArrowUp,
	faAward,
	faBan,
	faBars,
	faCalendarPlus,
	faCheck,
	faCheckCircle,
	faChevronLeft,
	faChevronRight,
	faClock,
	faExclamation,
	faExclamationCircle,
	faInfo,
	faInfoCircle,
	faLock,
	faMinus,
	faPaperPlane,
	faPen,
	faPlay,
	faPlus,
	faPlusCircle,
	faQuestion,
	faSadTear,
	faSave,
	faSearch,
	faSignInAlt,
	faSignOutAlt,
	faSmile,
	faStopwatch,
	faSyncAlt,
	faTachometerAlt,
	faTasks,
	faTimesCircle,
	faTrashAlt,
	faUser,
	faUserPlus,
} from '@fortawesome/free-solid-svg-icons'

library.add(
	faArrowDown,
	faArrowUp,
	faAward,
	faBan,
	faBars,
	faCalendarPlus,
	faCheck,
	faCheckCircle,
	faChevronLeft,
	faChevronRight,
	faClock,
	faExclamation,
	faExclamationCircle,
	faInfo,
	faInfoCircle,
	faLock,
	faMinus,
	faPaperPlane,
	faPen,
	faPlay,
	faPlus,
	faPlusCircle,
	faQuestion,
	faSadTear,
	faSave,
	faSearch,
	faSignInAlt,
	faSignOutAlt,
	faSmile,
	faStopwatch,
	faSyncAlt,
	faTachometerAlt,
	faTasks,
	faTimesCircle,
	faTrashAlt,
	faUser,
	faUserPlus,
)
