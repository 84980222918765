const fade = {
	initial: {
		opacity: 0,
	},
	animate: {
		opacity: 1,
	},
}

export default fade
