import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Slidenav = ({ side }) => (
	<a
		href="/#"
		className={`
			uk-visible@s
			uk-position-center-${side}
			uk-height-1-1
			uk-width-1-5
		`}
		data-uk-slideshow-item={side === 'left' ? 'previous' : 'next'}
	>
		<FontAwesomeIcon
			icon={`chevron-${side}`}
			className="uk-position-center"
			size="2x"
		/>
	</a>
)

export default Slidenav
